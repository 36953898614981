import React, { useEffect, Fragment } from 'react'
import { useHistory} from 'react-router-dom'
import { getParameterByName} from "../services/utils";
import config from '../config'
import { pureCloudService } from "../services/purecloud";
import constants from '../constants'
import backendAPI from '../services/backend'

const Main = (props) => {
    const history = useHistory()
    
    const notifyUserLogin = async (tempToken) => {
        try {
            console.log("Calling notify user login")
            sessionStorage.setItem(constants.R2S_TOKEN, tempToken);
            const notifyLoginResponse = await backendAPI.postUserLogin(tempToken);
            sessionStorage.setItem(constants.SESSION_KEY_USER_LOGIN_RESPONSE, JSON.stringify(notifyLoginResponse))
            sessionStorage.setItem(constants.SESSION_KEY_CONSENTAGREED, true);
            console.log(`notifyUserLogin ::: Notify user login response ${JSON.stringify(notifyLoginResponse)}`);
            //setLoginResponse(notifyLoginResponse);
            
            console.log('Navigating to Conversation Page')
            history.push("/conversations");
            
            //history.push("/management/users/");
            
    
        } catch (error) {
            console.log(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            
        }
    
    }

    useEffect(() => {
        const init = async () => {
            console.log('main.useEffect.init')
            var hash = window.location.hash.substring(1);
            var params = {}
            hash.split('&').map(hk => { 
            let temp = hk.split('='); 
                params[temp[0]] = temp[1] 
            });
            console.log(`Parameters are ${JSON.stringify(params)}`);
            const token = new URLSearchParams(window.location.search).get('token');
            console.log(`Token is ${JSON.stringify(token)}`);
            const error = params.error;
            if (token){
                sessionStorage.setItem(constants.R2S_TOKEN,token);              
                notifyUserLogin(token);
            }else if(sessionStorage.getItem(constants.R2S_TOKEN)){
                //notifyUserLogin();
                console.log(`User Already logged in`);
                console.log('Navigating to Conversation Page')
            history.push("/conversations");
            }else{
                history.push('/unauthorized');
            }
            if (error) {
                console.log('error:', error);
                history.push('/unauthorized');
            }
        }
        init()
    }, [history])

    return (<Fragment></Fragment>)
}


export default Main