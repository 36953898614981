import React, { useState, useEffect } from 'react';
import backend from '../../services/backend';
import moment from 'moment'
import constants from '../../constants'
import './ChatTranscriptView.css'
import sanitizeHtml from 'sanitize-html'

const PCChatTranscriptView = (props) => {
    const { recordings, id } = props
    const [chatTranscript, setChatTranscript] = useState()

    useEffect(() => { // init
        console.log('fetchMetadata:begin::init');
        const fetchMetadata = async () => {
            console.log('fetchMetadata:begin');
            try {
                console.log(`About to Fetch Chat Transcript view`);
                let transcript = await backend.getPCConversationMetadata(sessionStorage.getItem(constants.R2S_TOKEN), id, recordings)
                // const transcript = response.recordings[0].metadata.map(x => {
                //     if (x.from && x.body && x.bodyType === 'standard')
                //         return `${moment(x.utc).format("MMM Do YYYY, h:mm:ss a")} ${x.from}: ${x.body}`
                //     return undefined
                // }).filter(x => x).join('\r\n')
                console.log(`Response is ${JSON.stringify(transcript)}`)
                transcript = transcript.recordings[0].raw_response.content;
                console.log('transcript:', transcript)
                setChatTranscript(transcript)
            } catch (error) {
                console.log('error:', error)
            }
        }
        console.log('ChatTranscript.metadata.useeffect')
        fetchMetadata()
    }, [])

    return (
        <div>
            {chatTranscript &&
                <div className="container container-chat-transcript mx-2">
                    
                    
                    {/* <div className="card card-body mt-2 overflow-auto"
                                                                dangerouslySetInnerHTML={{ __html: sanitizeHtml(chatTranscript) }}
                    /> */}
                    <textarea>
                        {chatTranscript}
                    </textarea>
                </div>

            }
        </div>
    );
}

export default PCChatTranscriptView;