import React, { useState, Fragment, useEffect } from 'react';
import backend from '../../services/backend';
import constants from '../../constants'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'

import './EmailTranscriptView.css'

const PCEmailTranscriptView = (props) => {
    const [recordings, setRecordings] = useState()

    useEffect(() => { // init
        const fetchMetadata = async () => {
            try {
                console.log(`About to Fetch Email Transcript view`);
                const response = await backend.getPCConversationMetadata(sessionStorage.getItem(constants.R2S_TOKEN), props.id, props.recordings)
                let recordingsToRender=[];
                for(let recordingItem of response.recordings){
                    console.log(`Checking ${JSON.stringify(recordingItem)}`)
                    // if(recordingItem.metadata){
                    //     console.log('Meta data exists');
                    //     if(recordingItem.metadata.from){
                    //         console.log("Meta data From Exists");
                            recordingsToRender.push(recordingItem);
                    //     }else{
                    //         console.log("Meta Data From Field Doesnt exist in response");
                    //     }
                        
                        
                    // }else{
                    //     console.log('Meta data Doesnot exists');
                    // }
                }
                console.log(recordingsToRender);
                setRecordings(recordingsToRender)
                console.log('recordings:', props.recordings)
            } catch (error) {
                console.log('error:', error)
            }
        }
        console.log('EmailTranscript.recordings.useeffect')
        fetchMetadata()
    }, [props.id, props.recordings])

    return (
        <div>
            {recordings &&
                <Fragment>
                    <div className="container container-email-transcript mx-2">
                        <div className="row">
                            <div className="col">
                                <div className="my-3">
                                    <ul className="list-group list-group-flush">
                                        {
                                            recordings.map((x, i) => {
                                                return (
                                                    <li className="list-group-item px-0">
                                                        <a className="btn collapsed" data-toggle="collapse" role="button"
                                                            href={"#collapseRecording" + i} aria-expanded="true"
                                                            aria-controls={"collapseRecording" + i}>
                                                            Agent: {x.agentname} <span className="email-date-header">{moment(x.start_time).format("MMM Do YYYY, h:mm:ss a")}</span> <span class="mr-3 custom-arrow"></span>
                                                        </a>
                                                        <div className="collapse" id={"collapseRecording" + i}>
                                                            {/* <div className="custom-padding">
                                                                To: {x.metadata.to.map(x => x.email).join(';')}
                                                            </div>
                                                            <div className="custom-padding">
                                                                Subject: {x.metadata.subject}
                                                            </div> */}
                                                            <div className="card card-body mt-2 overflow-auto"
                                                                dangerouslySetInnerHTML={{ __html: sanitizeHtml(x.raw_response.content) }}
                                                            >
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default PCEmailTranscriptView;