import React, { useState, useEffect } from 'react'
import {MultiSelect} from "react-multi-select-component"
import './AccessReportSearchCriteria.css'
import moment from 'moment';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const AccessReportSearchCriteria = (props) => {
    const { actions } = props    
    const [selectedActions, setSelectedActions] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    const search = async () => {
        try {
                setStartSearch(true)
                if (!dateRange[0] || !dateRange[1]) return
                console.log('searching...')
                const searchCriteria = new Map()
                if (dateRange[0]) searchCriteria.set('startDate', dateRange[0])
                if (dateRange[1]) searchCriteria.set('endDate', dateRange[1])
                if (selectedActions) {
                    if (selectedActions.length === actions.length) // all
                        searchCriteria.set('actions', '*')
                    else
                        searchCriteria.set('actions', selectedActions.map(x => x.value))
                }
                
                props.search(searchCriteria)
        } catch (error) {
            console.log(error);                
        }
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }  

    function onSelectDate(dateArray, dateString) 
    {        
        console.log(dateString)
        setdateRange(dateString);
    }
    return (
        <div className="search-wrap pl-2">
            <div className='row justify-content-start'>
                <div className="col col-12 col-xl-3">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Actions</span>
                        </div>
                        <MultiSelect
                            options={actions}
                            value={selectedActions}
                            onChange={e => { setSelectedActions(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>
                <div className='col col-12 col-xl-auto'>
                    <div className="input-group d-inline-flex align-items-center w-auto">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Interval</span>
                        </div>
                        <RangePicker placement="bottomLeft" style={{borderRadius:"5px",padding:"7px"}} onChange={onSelectDate} />
                    </div>
                </div>
            </div>
            <br />
            <div className='row justify-content-start'>
                <div className='col-xs-2 pl-3 pr-2'>
                    <button type="button" className="btn btn-primary button-custom" onClick={search}>Search</button>
                </div>
                <div className='col-xs-2' >
                    <button type="button" className="btn btn-secondary button-custom" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )
}

export default AccessReportSearchCriteria