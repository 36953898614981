import React, { useState, useEffect } from 'react'
import { MultiSelect } from "react-multi-select-component"
import moment from 'moment'
import utils from '../../services/utils'
import constants, { SEARCH_PARAM_DEPARTMENT } from '../../constants'
import './twilioConversationsSearchCriteria.css'
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const TWConversationsSearchCriteria = (props) => {
    const { channels, queues, role, divisions, agents, conv_direction } = props
    const [dnis, setDNIS] = useState()
    const [ani, setANI] = useState()
    const [conversationID, setConversationID] = useState()

    const [selectedDivisions, setSelectedDivisions] = useState([])
    const [selectedAgents, setSelectedAgents] = useState([])
    const [selectedConvDirection, setSelectedConvDirection] = useState([])
    const [selectedQueues, setSelectedQueues] = useState([])
    const [startSearch, setStartSearch] = useState(false)
    const [dateRange, setdateRange] = useState([]); // 0th position - startDate , 1st position - end Date

    function onSelectDate(dateArray, dateString) {
        console.log(dateString)
        setdateRange(dateString);
    }

    // init from session storage
    useEffect(() => {
        try {
            //console.log(`ConversationsSearchCriteria:::departments are ${JSON.stringify(departments)}`)
            //console.log(`ConversationsSearchCriteria:::locations are ${JSON.stringify(locations)}`)
            //console.log(`ConversationsSearchCriteria:::channels are ${JSON.stringify(channels)}`)
            //console.log(`ConversationsSearchCriteria:::role are ${JSON.stringify(role)}`)
            //console.log(`ConversationsSearchCriteria:::agents are ${JSON.stringify(agents)}`)
            //console.log(`ConversationsSearchCriteria:::conv_direction are ${JSON.stringify(conv_direction)}`)
            sessionStorage.setItem(constants.R2S_CONVERSATIONS, '');
            const searchCriteriaTemp = JSON.parse(sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA));
            console.log(`ConversationsSearchCriteria:::: got search criteria as ${JSON.stringify(searchCriteriaTemp)}`);
            if (searchCriteriaTemp) {
                try {
                    if (searchCriteriaTemp.startDateTime) {
                        let tempStartDate = new Date(searchCriteriaTemp.startDateTime);
                        console.log(`Setting Start date time as ${JSON.stringify(searchCriteriaTemp.startDateTime)}`);
                        console.log(`Setting Start date time as ${tempStartDate}`);
                        console.log(`Setting Start date time as ${typeof tempStartDate}`);
                        //setStartDate(tempStartDate);
                    }
                    if (searchCriteriaTemp.endDateTime) {
                        let tempEndDate = new Date(searchCriteriaTemp.endDateTime);
                        console.log(`Setting Start date time as ${JSON.stringify(searchCriteriaTemp.endDateTime)}`);
                        console.log(`Setting Start date time as ${tempEndDate}`);
                        console.log(`Setting Start date time as ${typeof tempEndDate}`);
                        //setEndDate(tempEndDate);
                    }
                    if (searchCriteriaTemp.agent) {
                        console.log(`Setting Agent as ${searchCriteriaTemp.agent.split(',')}`);
                        let tempCriteria;
                        let selectedAgentTemp;
                        if (searchCriteriaTemp.agent == '*') {
                            selectedAgentTemp = agents.filter((x => x.value !== '*'));
                        } else {
                            tempCriteria = searchCriteriaTemp.agent.split(',');
                            selectedAgentTemp = agents.filter((x => tempCriteria.includes(x.value)));
                        }
                        console.log(`Setting Agent as ${JSON.stringify(selectedAgentTemp)}`);
                        setSelectedAgents(selectedAgentTemp);
                        //setEndDate(searchCriteriaTemp.endDateTime);
                    }

                    if (searchCriteriaTemp.conv_direction) {
                        console.log(`Setting Agent as ${searchCriteriaTemp.conv_direction}`);
                        if (searchCriteriaTemp.conv_direction == '*') {
                            setSelectedConvDirection(conv_direction.filter((x => x.value !== '*')));
                        } else {
                            setSelectedConvDirection(conv_direction.filter((x => x.value == searchCriteriaTemp.conv_direction)));
                        }
                    }


                    if (searchCriteriaTemp.ani) {
                        setANI(searchCriteriaTemp.ani);
                    }

                    if (searchCriteriaTemp.dnis) {
                        setDNIS(searchCriteriaTemp.dnis);
                    }

                    if (searchCriteriaTemp.conversationID) {
                        setConversationID(searchCriteriaTemp.conversationID);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            if (!searchCriteriaTemp) return;

        } catch (error) {
            console.log('conversationsSearchCriteria.useeffect.error', error)
        }
    }, [])

    const search = async () => {
        setStartSearch(true)
        if (!dateRange[0] || !dateRange[1]) return
        console.log('Searching...')
        const searchCriteria = new Map()
        if (dateRange[0]) {
            let tempStartDate = moment(dateRange[0], 'YYYY-MM-DD').set({ "hour": 0, "minute": 0 });
            searchCriteria.set(constants.SEARCH_PARAM_STARTDT, tempStartDate.toISOString());
        }

        if (dateRange[1]) {
            let tempEndDate = moment(dateRange[1], 'YYYY-MM-DD').set({ "hour": 23, "minute": 59 });
            searchCriteria.set(constants.SEARCH_PARAM_ENDDT, tempEndDate.toISOString());
        }



        if (selectedAgents) {
            //searchCriteria.set('selectedAgents', selectedAgents);
            if (selectedAgents.length === agents.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_AGENT, decodeURIComponent(selectedAgents.map(x => x.value)));
        }

        if (selectedConvDirection) {
            //searchCriteria.set('selectedAgents', selectedAgents);
            if (selectedConvDirection.length === conv_direction.length) // all
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, '*');
            else
                searchCriteria.set(constants.SEARCH_PARAM_DIRECTION, decodeURIComponent(selectedConvDirection.map(x => x.value)));
        }
        if (ani) searchCriteria.set(constants.SEARCH_PARAM_ANI, decodeURIComponent(ani));
        if (dnis) searchCriteria.set(constants.SEARCH_PARAM_DNIS, decodeURIComponent(dnis));
        if (conversationID) searchCriteria.set(constants.SEARCH_PARAM_CONVERSATIONID, conversationID);
        props.search(utils.mapToObj(searchCriteria))
        sessionStorage.setItem(constants.R2S_SEARCH_CRITERIA, JSON.stringify(utils.mapToObj(searchCriteria)))
    }
    const cancel = async () => {
        console.log('canceling...')
        props.cancel()
    }

    return (
        <div className="search-wrap pl-2 pb-2">
            <div className='row justify-content-start'>

                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">ANI</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={ani}
                            onChange={e => setANI(e.target.value)} />
                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">DNIS</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={dnis}
                            onChange={e => setDNIS(e.target.value)} />
                    </div>
                </div>

                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Conversation ID</span>
                        </div>
                        <input className='form-control conversations-custom-control' type='text' value={conversationID}
                            onChange={e => setConversationID(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className='row justify-content-start'>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Direction of the Call</span>
                        </div>
                        <MultiSelect className="multi-select direction-multi-select"
                            options={conv_direction}
                            value={selectedConvDirection}
                            onChange={e => { setSelectedConvDirection(e) }}
                            labelledBy="Select"
                        />

                    </div>
                </div>
                <div className='col col-12 col-xl-3'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span class="input-group-text">Agent Name</span>
                        </div>
                        <MultiSelect className="multi-select agents-multi-select"
                            options={agents}
                            value={selectedAgents}
                            onChange={e => { setSelectedAgents(e) }}
                            labelledBy="Select"
                        />
                    </div>
                </div>

                <div className='col col-12 col-xl-auto'>
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Interval *</span>
                        </div>

                        <RangePicker size="large" aria-required placement="bottomLeft" style={{ borderRadius: "5px" }} onChange={onSelectDate} />
                    </div>
                </div>


            </div>
            <br />
            <div className='row justify-content-start'>
                <div className='col-xs-2 pl-3 pr-2'>
                    <button type="button" className="btn btn-primary button-custom" onClick={search}>Search</button>
                </div>
                <div className='col-xs-2' >
                    <button type="button" className="btn btn-secondary button-custom" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>

    )


}

export default TWConversationsSearchCriteria