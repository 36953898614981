import React, { useState, useEffect } from 'react'
import ReactTable from "react-table-6";
import { Input, Collapse, List, ListInlineItem, Tooltip } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import Header from '../header/Header';
import Footer from '../footer/Footer';
import TWConversationsSearchCriteria from '../twilioConversationsSearchCriteria/twilioConversationsSearchCriteria'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faCog , faDownload,faFileZipper , faCloudArrowDown} from '@fortawesome/free-solid-svg-icons'
import backend from '../../services/backend'
import AlertMessage from '../alertMessage/AlertMessage'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import ConfirmationModal from '../Modals/confirmationModal/ConfirmationModal'
import constants from '../../constants'
import moment from 'moment-timezone'
import utils from '../../services/utils'
import "react-table-6/react-table.css";
import './Home.css'

const pageSize = sessionStorage.getItem(constants.SESSION_KEY_CONVERSATIONS_PAGESIZE) ? parseInt(sessionStorage.getItem(constants.SESSION_KEY_CONVERSATIONS_PAGESIZE)) : 15

const Home = (props) => {
    const [error, setError] = useState()
    const [message, setMessage] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [conversations, setConversations] = useState([])
    const [user, setUser] = useState(undefined)
    const [role, setRole] = useState()
    const [searchAlertVisible, setSearchAlertVisible] = useState(false)
    const [searchAlertMessage, setSearchAlertMessage] = useState()
    const [columns, setColumns] = useState([])
    const [waitingHeader, setWaitingHeader] = useState()
    const history = useHistory()

    // Tooltips
    const [searchConversationsTooltipOpen, setSearchConversationsTooltipOpen] = useState(false)
    const [hideColumnsTooltipOpen, setHideColumnsTooltipOpen] = useState(false)
    const [downloadTooltipOpen, setDownloadTooltipOpen] = useState(false)
    const [exportTooltipOpen, setExportTooltipOpen] = useState(false)
    

    // Collapsable
    const [isColumnsSettingsOpen, setIsColumnsSettingsOpen] = useState(false)
    const [isConversationsSearchCriteriaOpen, setIsConversationsSearchCriteriaOpen] = useState(true)

    //columns to display
    const [columnsToDisplay, setColumnsToDisplay] = useState()

    const exportConversation = async (searchCriteria) => {
        console.log(`Loading conversations : exportConversation # ${JSON.stringify(searchCriteria)}`);
        try {
            setWaitingHeader('Loading export conversation')
            setIsWaitingModalOpen(true)
            console.log(JSON.stringify(searchCriteria));
            if(role != 'admin'){
                console.log(`Search Criteria before submitting is ${JSON.stringify(searchCriteria)}`);
              
                if(searchCriteria.division && searchCriteria.division== '*'){
                    console.log(user.authorizedDivisions);
                    let filteredDivision = user.divisions.filter((x => user.authorizedDivisions.includes(x.value)))
                    console.log(filteredDivision);
                    filteredDivision = filteredDivision.map(a => a.value);
                    console.log(filteredDivision);
                    searchCriteria.division = filteredDivision.toString();
                    console.log(`Fix to search criteria with Division ${JSON.stringify(searchCriteria)}`);
                }
                if(searchCriteria.queues && searchCriteria.queues== '*'){
                    console.log(user.authorizedQueues);
                    let filteredQueues = user.queues.filter((x => user.authorizedQueues.includes(x.value)))
                    console.log(filteredQueues);
                    filteredQueues = filteredQueues.map(a => a.value);
                    console.log(filteredQueues);
                    searchCriteria.queues = filteredQueues.toString();
                    console.log(`Fix to search criteria with queues ${JSON.stringify(searchCriteria)}`);
                }
            }
            const response = await backend.exportConversations(sessionStorage.getItem(constants.R2S_TOKEN), searchCriteria)
            
            console.log('fetchData.response:', JSON.stringify(response))
            setIsWaitingModalOpen(false)
            setMessage(`Export Request is submitted! \n , please check download page for status of export`);
            setTimeout(() => {
                history.push('/downloads');
              }, 2000);
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    const searchConversation = async (searchCriteria) => {
        console.log(`Loading conversations : searchConversation # ${JSON.stringify(searchCriteria)}`);
        try {
            setWaitingHeader('Loading conversations')
            setIsWaitingModalOpen(true)
           
            if(role != 'admin'){
                console.log(`Search Criteria before submitting is ${JSON.stringify(searchCriteria)}`);
              
                
            }                       
            const response = await backend.searchTWConversations(sessionStorage.getItem(constants.R2S_TOKEN), searchCriteria)
            console.log('fetchData.response:', JSON.stringify(response))
            if (response.records_returned === 0) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`No records returned. Please verify your search criteria.`)
            }
            if (response.records_returned < response.total_records) {
                setSearchAlertVisible(true)
                setSearchAlertMessage(`Showing ${response.records_returned} of ${response.total_records} records. To find specific set of results, please refine your search criteria.`)
            }
            setConversations(response.conversations);
            sessionStorage.setItem(constants.R2S_CONVERSATIONS, JSON.stringify(response.conversations))
        } catch (error) {
            setError(`An error occured while fetching data:${JSON.stringify(error.message)}`)
            console.log(error)
        } finally {
            setIsWaitingModalOpen(false)
        }
    }

    useEffect(() => { // initial load
        console.log('Loading Home JS ')
        console.log('Home:::'+props.role)
        
        const fetchSettings = async () => {
            try {
                console.log('Loading Home JS :: fetchSettings')
                setWaitingHeader('Loading conversation settings')
                setIsWaitingModalOpen(true)

                // Get users me
                const response = await backend.getUsersMe(sessionStorage.getItem(constants.R2S_TOKEN))
                console.log(`Home::::::::::response keys are ${Object.keys(response.user)}`);
                for (const [userKey, userValue] of Object.entries(response.user)) {
                //    console.log(`Home::::::::::${userKey} are ${JSON.stringify(userValue)}`);
                    //console.log(`Home::::::::::${userKey} are ${Object.keys(userValue)}`);
                }
                

                console.log(`authorizedQueues are ${JSON.stringify(response.user.authorizedQueues)} `);
                console.log(`authorizedDivisions are ${JSON.stringify(response.user.authorizedDivisions)} `);

                if(!(response.user.authorizedQueues == 'All'||response.user.authorizedQueues == '*'||response.user.authorizedQueues == ['*'])){
                    console.log(`authorizedQueues are ${JSON.stringify(response.user.authorizedQueues)} `);
                    response.user.queues = response.user.queues.filter((x => x.value == '*'||response.user.authorizedQueues.includes(x.value)));
                }else{
                    console.log('Authorized to use all Queues');
                }

                if(!(response.user.authorizedDivisions == 'All'||response.user.authorizedDivisions == '*'||response.user.authorizedDivisions == ['*'])){
                    console.log(`authorizedQueues are ${JSON.stringify(response.user.authorizedDivisions)} `);
                    response.user.divisions = response.user.divisions.filter((x => x.value == '*'||response.user.authorizedDivisions.includes(x.value)));
                }else{
                    console.log('Authorized to use all Queues');
                }
                
                sessionStorage.setItem(constants.R2S_USER_ROLE,response.user.role.value);
                setRole(response.user.role.value)
                setUser(response.user)
                console.log(`queues are ${JSON.stringify(response.user.queues)}`);
                console.log(`divisions are ${JSON.stringify(response.user.divisions)}`);
                console.log(`role are ${JSON.stringify(response.user.role)}`);
                console.log(`conv_direction are ${JSON.stringify(response.user.conv_direction)}`);
                console.log(`Agents are ${JSON.stringify(response.user.users)}`);
                console.log(`pcusers are ${JSON.stringify(response.user.pcusers)}`);
                console.log(`twusers are ${JSON.stringify(response.user.twusers)}`);
                console.log(`pcqueues are ${JSON.stringify(response.user.pcqueues)}`);
                console.log(`twilio columns are ${JSON.stringify(response.user.twConversationColumns)}`);
                        
                // Get user conversation settings
                const conversationsColumns = response.user.twConversationColumns.filter(x => x.visible)
                const columnsToDisplay = {}
                for (const column of conversationsColumns) {
                    columnsToDisplay[column.value] = column.visible
                }
                setColumnsToDisplay(columnsToDisplay)

                // Build re set columns
                const columns = conversationsColumns.map(x => {
                    const col = {
                        Header: x.label,
                        accessor: x.value,
                        headerClassName: 'headerColumn',
                        // Filter: ({ filter, onChange }) => { return customFilterInput(filter, onChange, x.value) },
                        show: x.visible,
                        Cell: row => {
                            return (
                                <div className="overflow div-center">
                                    {row.original[x.value]}
                                </div>
                            )
                        }
                    }

                    if (x.type === 'date') {
                        col.Cell = row => (<div className="overflow div-center">{moment(row.original[x.value]).format("MMM Do YYYY, h:mm:ss a Z")}</div>)
                        col.filterable = false
                    }

                    return col
                })
                setColumns(columns)
                console.log(user);
            } catch (error) {
                setError(`An error occured while fetching conversation settings:${JSON.stringify(error.message)}`)
                console.log(error)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        console.log('Home.useEffect.init')
        fetchSettings()
        console.log(`Home.useEffect.conversation ${JSON.stringify(sessionStorage.getItem(constants.R2S_TW_CONVERSATIONS))}`);
        try {
            if(sessionStorage.getItem(constants.R2S_TW_CONVERSATIONS) != undefined){
                console.log('Home.useEffect.setconversation conversationsExists');
                console.log(`${JSON.stringify(sessionStorage.getItem(constants.R2S_TW_CONVERSATIONS))}`);
                if(sessionStorage.getItem(constants.R2S_TW_CONVERSATIONS)){
                    const conversations = JSON.parse(sessionStorage.getItem(constants.R2S_TW_CONVERSATIONS));
                    if (conversations){ setConversations(conversations)}
                }
            }
        } catch (error) {
            console.log(error);
        }
        
    }, [])

    useEffect(() => { // update columns show property
        console.log('columnsToDisplay.useEffect.trigger:', columnsToDisplay)
        console.log(` columns are ${columns}`);
        if (!columnsToDisplay || !columns || columns.length === 0) {
            console.log('columnsToDisplay.useEffect.exiting use effect')
            return
        }
        console.log('columnsToDisplay.useEffect.processing use effect')
        for (const key in columnsToDisplay) {
            if (Object.hasOwnProperty.call(columnsToDisplay, key)) {
                columns.find(x => x.accessor === key).show = columnsToDisplay[key]
            }
        }
        setColumns([...columns])
        console.log(`column details ${JSON.stringify(columns)}`);

    }, [columnsToDisplay])

    const DownloadReport = async () => {        
        utils.JSONToCSVConvertor(conversations, `R2S_Search_results_${moment().format()}`, true)
    }

    // const exportReport = async () => {
    //     let serachParams = JSON.parse(sessionStorage.getItem(constants.R2S_SEARCH_CRITERIA));
    //     exportConversation(serachParams);
    // }


    const search = async (searchParams) => {
        console.log('getRecordings.searchCriteria:', searchParams);
        console.log('getRecordings.typeof searchCriteria:', typeof searchParams);
        searchConversation(searchParams);
        toggleConversationsSearchCriteria(false)
    }

    const toggleColumnsSettings = () => {
        setIsConversationsSearchCriteriaOpen(false)
        setIsColumnsSettingsOpen(!isColumnsSettingsOpen)
    }
    const toggleConversationsSearchCriteria = () => {
        setIsColumnsSettingsOpen(false)
        setIsConversationsSearchCriteriaOpen(!isConversationsSearchCriteriaOpen)
    }

    return (
        <div id="component-wrap">
            <Header role={role} />
            <WaitingModal
                isOpen={isWaitingModalOpen}
                header={waitingHeader}
            />
            <AlertModal
                isOpen={!!error}
                header='Error'
                toggle={() => { setError(undefined) }}
                body={error}
            />
            <ConfirmationModal
                    isOpen={!!message}
                    header='Success!'
                    toggle={() => { setMessage(undefined) }}
                    body={message}
                    />
            <Tooltip placement="bottom" isOpen={searchConversationsTooltipOpen}
                target="searchConversations"
                toggle={() => { setSearchConversationsTooltipOpen(!searchConversationsTooltipOpen) }}>
                Search conversations
            </Tooltip>
            <Tooltip placement="bottom" isOpen={hideColumnsTooltipOpen}
                target="hideColumns"
                toggle={() => { setHideColumnsTooltipOpen(!hideColumnsTooltipOpen) }}>
                Show/Hide columns
            </Tooltip>
            {/* <Tooltip placement="bottom" isOpen={downloadTooltipOpen}
                target="downloadReport"
                toggle={() => { setDownloadTooltipOpen(!downloadTooltipOpen) }}>
                Download report to CSV
            </Tooltip>
            <Tooltip placement="bottom" isOpen={exportTooltipOpen}
                target="exportReport"
                toggle={() => { setExportTooltipOpen(!exportTooltipOpen) }}>
                Initiate Recording Export Process
            </Tooltip> */}
            <div id="component-toolbar">
                <button id="searchConversations" onClick={toggleConversationsSearchCriteria}><FontAwesomeIcon className="fa-2x" icon={faFilter} /></button>
                <button id="hideColumns" onClick={toggleColumnsSettings}><FontAwesomeIcon className="fa-2x" icon={faCog} /></button>
                {/*<button id="downloadReport"
                    disabled={conversations && conversations.length > 0 ? false : true}
                    onClick={DownloadReport}><FontAwesomeIcon className="fa-2x" icon={faDownload} />
                </button>
                 <button id="exportReport"
                    disabled={conversations && conversations.length > 0 ? false : true}
                    onClick={exportReport}><FontAwesomeIcon className="fa-2x" icon={faCloudArrowDown} />
                </button> */}
            </div>
            <AlertMessage
                message={searchAlertMessage}
                visible={searchAlertVisible}
                setVisible={x => { setSearchAlertVisible(x) }}
            />
            <Collapse isOpen={isColumnsSettingsOpen}>
                <List type="inline" className="ml-2">
                    {columns && columns.map((column) => {
                        return (
                            <ListInlineItem>
                                <Input addon type="checkbox"
                                    onChange={(e) => {
                                        e.persist()
                                        setColumnsToDisplay((prevState) => ({ ...prevState, [column.accessor]: e.target.checked }))
                                    }}
                                    checked={columnsToDisplay[column.accessor]}
                                /> {column.Header}{'\u00A0'}
                            </ListInlineItem>
                        )
                    })}
                </List>
            </Collapse>
            <Collapse isOpen={isConversationsSearchCriteriaOpen}>
                {user &&
                    <TWConversationsSearchCriteria
                        search={search}
                        cancel={() => { toggleConversationsSearchCriteria(false) }}
                        channels={user.channels ? user.channels.filter((x => x.value !== '*')) : []}
                        role={user.role ? user.role.value : undefined}
                        agents = {user.twusers  ? user.twusers .filter((x => x.value !== '*')) : []}
                        conv_direction={user.convDirection ? user.convDirection.filter((x => x.value !== '*')) : []}
                    />
                }
            </Collapse>
            <ReactTable
                showPageSizeOptions={true}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={pageSize}
                minRows={0}
                filterable
                // manual
                // pages={pages}
                className="-highlight -striped table-container"
                data={conversations}
                columns={columns}
                getTdProps={(state, rowInfo, column, instance) => {
                    return {
                        onClick: (e, handleOriginal) => {
                            try {
                                if (!rowInfo) return
                                history.push(`/twilio/${rowInfo.original.conversation_id}/details`)
                            } catch (error) {
                                setError('An error occured while selecting a row, please check console logs for more details')
                                console.log(error)
                            }
                        },
                        style: {
                            cursor: 'pointer'
                        }
                    }
                }}
                onPageSizeChange={(pageSize) => {
                    sessionStorage.setItem(constants.SESSION_KEY_CONVERSATIONS_PAGESIZE, pageSize)
                }}
                getTrProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                getTrGroupProps={() => {
                    return {
                        style: {
                            flex: '0 0 auto'
                        }
                    }
                }}
                defaultFilterMethod={(filter, row) => {
                    const id = filter.pivotId || filter.id;
                    return (row[id] !== undefined ?
                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                        : true
                    );
                }}
            />
            <Footer />
        </div>
    )
}

export default Home