import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import backend from '../../services/backend'
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileVideo, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import RecordingPlaybackModal from '../Modals/recordingPlaybackModal/RecordingPlaybackModal'
import EmailTranscriptView from '../emailTranscriptView/EmailTranscriptView'
import ChatTranscriptView from '../chatTranscriptView/ChatTranscriptView'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import constants from '../../constants'
import moment from 'moment'

import './TwilioConversationDetails.css'
import "react-table-6/react-table.css";

const TWConversationDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [recordings, setRecordings] = useState([])
    const [conversation, setConversation] = useState()
    const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] = useState(false)
    const [recordingPlayback, setRecordingPlayback] = useState()
    const [role, setRole] = useState()
    const history = useHistory()
    const { id } = useParams()

    useEffect(() => { // init load
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        console.log('Conversation Details role is'+role);
        const fetchConversation = async () => {
            try {
                setIsWaitingModalOpen(true)
                let response = await backend.getTWConversation(sessionStorage.getItem(constants.R2S_TOKEN), id)
                console.log('getConversation.response:', response[0])
                let pcrecordings = {};
                pcrecordings["recordingid"] =response[0].recordingid;
                pcrecordings.url = response[0].file_url;
                pcrecordings["agentid"] =response[0].agentid;
                pcrecordings["mediatypename"]= response[0].mediatypename;
                pcrecordings["media_type"]= response[0].mediatypename;
                if(response[0].mediatypename == 'SMS'){
                    pcrecordings["media_type"]= 'chat';
                }
                console.log(`Done setting up recordings ${JSON.stringify(pcrecordings)}`);
                 
                setRecordings([pcrecordings]);
                console.log(`Done setting up recordings ${JSON.stringify(pcrecordings)}`);
                console.log(`Response is ${JSON.stringify(response)}`);
                console.log(`Response First element is ${JSON.stringify(response[0])}`);
                let tempCOnv = response[0];
                console.log(`tempCOnv is ${JSON.stringify(tempCOnv)}`);
                console.log(`Type of tempCOnv is ${typeof tempCOnv}`);
                setConversation(tempCOnv);
                 console.log(`Recording Meta data request - ${JSON.stringify(pcrecordings)}`);
                 if (tempCOnv.mediatypename.toLowerCase()=='email' || tempCOnv.mediatypename.toLowerCase()=='chat' ||tempCOnv.mediatypename.toLowerCase()=='sms') {
                     console.log(`going to call recording meta data`);
                     response = await backend.getPCConversationMetadata(sessionStorage.getItem(constants.R2S_TOKEN), id, [pcrecordings])
                     console.log(`Recording Meta data API call response is ${JSON.stringify(response)}`);
                     setRecordings(response.recordings)
                     
                 }
            } catch (error) {
                console.log('fetchConversation error in the recording generation:,', error)
                setError(`An error occured while fetching the conversation:${JSON.stringify(error.message)}`)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchConversation()
    }, [id])

    const handleRecordingClick = (recording) => {
        console.log('handleRecordingClick::::::', recording)
        setRecordingPlayback(recording)
        toggleRecordingPlaybackModal()
    }

    const toggleRecordingPlaybackModal = () => setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen)

    const conditionallyRenderConversation = () => {
        console.log(`Result Conversation is ${JSON.stringify(conversation)}`);
        if (!conversation) return
        switch (conversation.mediatypename.toLowerCase()) {
            case 'chat':
                console.log(`Going to Render MediaType Chat with ${JSON.stringify(recordings)}`);
                return <Fragment>
                    {recordings &&
                        <ChatTranscriptView
                            id={conversation.conversation_id}
                            recordings={recordings}
                        />}
                </Fragment>

            case 'email':
            case 'sms':
                console.log(`Going to Render MediaType Email`);
                return <Fragment>
                    {recordings &&
                        <EmailTranscriptView
                            id={conversation.conversation_id}
                            recordings={recordings}
                        />}
                </Fragment>
            default: // voice
            console.log(`Going to render MediaType Call`);
            console.log(`Going to render ${JSON.stringify(recordings)}`);
                return <div className="px-2">
                    <Row>
                        <Col>
                            <span className="details-header">Conversation Recordings</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReactTable
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                defaultPageSize={10}
                                minRows={0}
                                className="-highlight -striped table-container"
                                data={recordings}
                                columns={[
                                    {
                                    Header: 'Agent',
                                    accessor: 'agentid',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                        return (
                                            <div className="overflow div-center">
                                                {row.original.agentid}
                                            </div>
                                        )
                                    }
                                }
                                , 
                                {
                                    Header: 'Play',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                        console.log(row.original.mediatypename.toLowerCase());
                                        switch (row.original.mediatypename.toLowerCase()) {
                                            case 'call': 
                                            case 'phone': 
                                                console.log(`Going to render Call  recording`);return (
                                                <div className="div-center">
                                                    <button onClick={() => { handleRecordingClick(row.original) }}>
                                                        <FontAwesomeIcon icon={faPlay} />
                                                    </button>
                                                </div>
                                            )
                                            default: return <Fragment></Fragment>
                                        }
                                    }
                                }
                                ]}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer'
                                        }
                                    }
                                }}
                                getTrProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                getTrGroupProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                // defaultFilterMethod={(filter, row) => {
                                //     const id = filter.pivotId || filter.id;
                                //     return (row[id] !== undefined ?
                                //         String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                                //         : true
                                //     );
                                // }}
                              
                            />  
                        </Col>
                    </Row>
                </div>
        }
    }

    return (
        <div id="component-wrap">
            <Header role={role} />
            <div id="main">
                <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Loading conversation details'
                />
                <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                />

                <RecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => { toggleRecordingPlaybackModal() }}
                    recording={recordingPlayback}
                />

                <div className="px-2">
                    <Row>
                        <Col>
                            <button onClick={() => { history.push('/twilio') }}>
                                <FontAwesomeIcon className="fa-2x" icon={faChevronCircleLeft} />
                            </button>
                            <span className="conversations-details-header-text">Conversation Details - {conversation && conversation.conversation_id}</span>
                            <hr />
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Start Date</span><span className="details-value">{conversation && moment(conversation.conversationstartutc).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                        <Col>
                            <span className="details-header">End Date</span><span className="details-value">{conversation && moment(conversation.conversationendutc).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Agent</span><span className="details-value">{conversation && conversation.agentid}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Conversation Direction</span><span className="details-value">{conversation && conversation.calldirection}</span>
                        </Col>
                    </Row>
                    <Row xs="2">
                        <Col>
                            <span className="details-header">ANI</span><span className="details-value">{conversation && conversation.ani}</span>
                        </Col>
                        <Col>
                            <span className="details-header">DNIS</span><span className="details-value">{conversation && conversation.dnis}</span>
                        </Col>
                    </Row>                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Call Duration</span><span className="details-value">{conversation && conversation.calldurationseconds }</span>
                        </Col>
                        <Col>
                            <span className="details-header">Media Type</span><span className="details-value">{conversation && conversation.mediatypename }</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </div>
                {conditionallyRenderConversation()}
            </div>
            <Footer />
        </div>
    )
}

export default TWConversationDetails