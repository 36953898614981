import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, InputGroup, InputGroupAddon, InputGroupText, Input, Label } from 'reactstrap'
import {MultiSelect} from "react-multi-select-component"
import './UsersSearchCriteria.css'


const UsersSearchCriteria = (props) => {
    const {clients, stateSelected, roles, search, cancel} = props
    const [selectedClientIDs, setSelectedClientIDs] = useState([])
    const [selectedRole, setSelectedRole] = useState([])
    const [resultSetLimit, setResultSetLimit] = useState()
    const [focusedInput, setFocusedInput] = useState()
    const [startSearch, setStartSearch] = useState(false)
    const [selectedState, setSelectedState] = useState(stateSelected ? stateSelected : false)


    const setData = async () => {
        setStartSearch(true)
        console.log('searching...')
        const searchCriteria = new Map()
        if (selectedRole) {
            if (selectedRole.length === roles.length) // all
                searchCriteria.set('roles', '*')
            else
                searchCriteria.set('roles', selectedRole.map(x => x.value))
        }

        console.log(selectedState)
        searchCriteria.set('disabled', selectedState)

        if (resultSetLimit) searchCriteria.set('resultLimit', resultSetLimit)
        search(searchCriteria)
    }
    // const cancel = async () => {
    //     console.log('canceling...')
    //     cancel()
    // }

    const getContainerClassNames = () => {
        return !focusedInput ? 'themed-container, container-search-200px' : 'container-search-400px'
    }


    useEffect(() => {

    }, [])

    return (
        <div className="search-wrap pl-2">
          <div className='row justify-content-start'>

                <div className='col col-12 col-xl-3' >
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Roles</span>
                        </div>
                        <MultiSelect className = "users-custom-control"
                            options={roles}
                            value={selectedRole}
                            onChange={e => { setSelectedRole(e) }}
                            labelledBy="Select"
                        />
                  </div>
                </div>
                <div className='col col-12 col-xl-3' >
                    <div className="input-group">
                        <div className="input-group-prepend">
                        <Label>
                        <Input addon type="checkbox" onChange={e => setSelectedState(e.target.checked)} defaultChecked={selectedState} />
                        &nbsp;
                        Disabled </Label>
                        </div>
                    </div>
                </div>
            </div>

            <br />

             <div className='row justify-content-start'>
                <div className='col-xs-2 pl-3 pr-2'>
                    <button type="button" className="btn btn-primary button-custom" onClick={setData}>Search</button>
                </div>
                <div className='col-xs-2' >
                    <button type="button" className="btn btn-secondary button-custom" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default UsersSearchCriteria